import {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Box, MenuItem, Select, TextField, Typography } from '@mui/material'
import SectionLayout from '../SectionLayout'
import BookmarkIcon from '@mui/icons-material/Bookmark'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import { ExerciseRequestContext } from '../../../../contexts/exercise-request.context'
import {
  convertToMGRS,
  mgrsToLonlat,
  decimalToDMS,
  dmsToDecimal,
} from '../../../../utils/functions'
import {
  createExerciseLocation,
  deleteLocationById,
  getLocationElevation,
  updateLocationById,
} from '../../../../services/exercise-request-form.service'
import { useToast } from '../../../../contexts/toast.context'
import { SelectWithSearch } from '../../../shared/SearchSelect'
import { AuthContext } from '../../../../contexts/auth.context'
import { useDebounce } from '../../../../hooks/useDebounce'

import styles from '../SectionLayout/styles.module.scss'

interface IProps {
  isLightMode: boolean
  setValue: Dispatch<SetStateAction<number>>
}

const LocationSection: FC<IProps> = ({ isLightMode, setValue }) => {
  const [MGRS, setMGRS] = useState({ dirty: false, value: '' })
  const [transmitterLocation, setTransmitterLocation] = useState('')
  const [radius, setRadius] = useState(0)
  const [region, setRegion] = useState(0)
  const [isDelete, setIsDelete] = useState(false)
  const [template, setTemplate] = useState(0)
  const [elevation, setElevation] = useState(0)
  const [locationType, setLocationType] = useState('deg/min/sec')
  /*
   * ILonLat holds state for a lon or lat in either deg/min/sec or decimals.
   *
   * When a user enters one, set dirty to true to recompute the other (as well as the MGRS).
   *
   * We allow numbers or strings in these fields to accommodate in-progress typing
   * for example "-" on the way to "-30"
   * or "5." or the way to "5.5".
   * If an onChange listener converted them to numbers immediately,
   * it would undo the user's keystrokes as they type.
   */
  interface ILonLat {
    decimals: string | number
    degrees: string | number
    minutes: string | number
    seconds: string | number
    dirty: boolean
  }
  const [latitude, setLatitude] = useState<ILonLat>({
    decimals: 0,
    degrees: 0,
    minutes: 0,
    seconds: 0,
    dirty: false,
  })
  const [longitude, setLongitude] = useState<ILonLat>({
    decimals: 0,
    degrees: 0,
    minutes: 0,
    seconds: 0,
    dirty: false,
  })

  const { showToast } = useToast()
  const { user } = useContext(AuthContext)
  const debouncedMGRS = useDebounce(MGRS, 1000)
  const debouncedLatitude = useDebounce(latitude, 1000)
  const debouncedLongitude = useDebounce(longitude, 1000)

  const {
    stateRegions,
    locationTemplates,
    selectedExerciseRequest,
    exerciseRequestLocations,
    setExerciseRequestLocations,
    selectedExerciseRequestLocation,
    setSelectedExerciseRequestLocation,
  } = useContext(ExerciseRequestContext)

  const handleAdd = async () => {
    if (selectedExerciseRequest && user) {
      const data = await createExerciseLocation(selectedExerciseRequest.id)

      if (data.success && data.data) {
        setExerciseRequestLocations([...exerciseRequestLocations, data.data])
        showToast('success', 'Location created successfully')
      }
    }
  }

  const handleClose = () => {
    setIsDelete(false)
  }

  /*
   * Parses the input as a float, but converts NaN results to the fallback (undefined if you don't give something).
   */
  const parseFloatOrElse = (v: any, fallback?: any) => {
    const ret = parseFloat(v)
    if (isNaN(ret)) return fallback
    else return ret
  }

  const computeDirection = (degrees: any, isLatitude: boolean) => {
    const v = parseFloatOrElse(degrees, 0)

    if (isLatitude) {
      return v >= 0 ? 'N' : 'S'
    } else {
      return v >= 0 ? 'E' : 'W'
    }
  }

  const handleSave = async () => {
    if (selectedExerciseRequest && selectedExerciseRequestLocation) {
      const data = await updateLocationById(
        selectedExerciseRequestLocation.id,
        {
          rad: radius,
          elevation,
          lat_deg: parseFloatOrElse(latitude.degrees),
          lat_min: parseFloatOrElse(latitude.minutes),
          lat_sec: parseFloatOrElse(latitude.seconds),
          lat_NS: computeDirection(latitude.degrees, true),
          lon_deg: parseFloatOrElse(longitude.degrees),
          lon_min: parseFloatOrElse(longitude.minutes),
          lon_sec: parseFloatOrElse(longitude.seconds),
          lon_EW: computeDirection(longitude.degrees, false),
          loc_template: template,
          state_region_id: region,
          transmitter_loc: transmitterLocation,
        },
      )

      if (data.success && data.data) {
        setExerciseRequestLocations(
          exerciseRequestLocations.map((location) =>
            data.data && location.id === data.data.id ? data.data : location,
          ),
        )
        setSelectedExerciseRequestLocation(data.data)

        showToast('success', 'Location updated successfully')
      }
    }
    handleClose()
  }

  const handleDelete = async () => {
    if (selectedExerciseRequestLocation) {
      const data = await deleteLocationById(selectedExerciseRequestLocation.id)

      if (data.success) {
        setExerciseRequestLocations(
          exerciseRequestLocations.filter(
            (location) => location.id !== selectedExerciseRequestLocation.id,
          ),
        )
        showToast('success', 'Location Removed successfully')

        setSelectedExerciseRequestLocation(null)
      }
    }
    handleClose()
  }

  /*
   * If the backend gives us a direction,
   * make sure the degrees have a sign that agrees.
   * This function works for decimals too.
   */
  const correctDegreesGivenDirection = (
    degrees: number,
    direction: string | null,
  ) => {
    const degreesPositive = Math.abs(degrees)

    if (direction == 'S' || direction == 'W') return -degreesPositive
    else return degreesPositive
  }

  const handleTemplateChange = (templateId: number) => {
    setTemplate(templateId)
    const locationTemplate = locationTemplates.find(
      (elem) => elem.id === templateId,
    )

    if (locationTemplate) {
      setRegion(locationTemplate.state_region_type_id)
      setTransmitterLocation(locationTemplate.transmitter_location)
      const latitudeDecimals = dmsToDecimal(
        locationTemplate.latitude_deg,
        locationTemplate.latitude_min,
        locationTemplate.latitude_sec,
        locationTemplate.latitude_ns,
      )
      const longitudeDecimals = dmsToDecimal(
        locationTemplate.longitude_deg,
        locationTemplate.longitude_min,
        locationTemplate.longitude_sec,
        locationTemplate.longitude_ew,
      )

      setLatitude({
        degrees: locationTemplate.latitude_deg,
        minutes: locationTemplate.latitude_min,
        seconds: locationTemplate.latitude_sec,
        decimals: latitudeDecimals,
        dirty: false,
      })
      setLongitude({
        degrees: locationTemplate.longitude_deg,
        minutes: locationTemplate.longitude_min,
        seconds: locationTemplate.longitude_sec,
        decimals: longitudeDecimals,
        dirty: false,
      })
      setElevation(locationTemplate.elevation || 0)
    }
  }

  const lonlatIsReasonable = () => {
    const lon = parseFloatOrElse(longitude.decimals)
    const lat = parseFloatOrElse(latitude.decimals)

    if (lon == null || lat == null) return false
    return -90 <= lat && lat <= 90 && -180 <= lon && lon <= 180
  }

  const computeMGRSFromLonlat = () => {
    try {
      setMGRS({
        value: convertToMGRS(
          parseFloatOrElse(latitude.degrees, 0),
          parseFloatOrElse(latitude.minutes, 0),
          parseFloatOrElse(latitude.seconds, 0),
          computeDirection(latitude.degrees, true),
          parseFloatOrElse(longitude.degrees, 0),
          parseFloatOrElse(longitude.minutes, 0),
          parseFloatOrElse(longitude.seconds, 0),
          computeDirection(longitude.degrees, false),
        ),
        dirty: false,
      })
    } catch (err: any) {
      setMGRS({ value: '', dirty: false })
    }
  }

  /*
   * Recomputes the different lonlat representations from the user-entered one.
   *
   * Users can enter the lonlat as either degrees/minutes/seconds, decimals, or MGRS.
   * The user's current input method is stored in locationType.
   * Whenever we get new user input, we mark the mgrs/lon/lat as dirty,
   * then call this function to compute the other two representations.
   */
  const computeLonlatFromLocationType = () => {
    if (MGRS.dirty) {
      if (locationType !== 'MGRS') {
        console.error("MGRS was dirty was the user isn't editing it")
        return
      }

      try {
        const [lon, lat] = mgrsToLonlat(MGRS.value)
        setLatitude({
          ...latitude,
          decimals: lat,
          ...decimalToDMS(lat, true),
          dirty: false,
        })
        setLongitude({
          ...longitude,
          decimals: lon,
          ...decimalToDMS(lon, false),
          dirty: false,
        })
      } catch (err: any) {
        // This is guaranteed to be invalid while the user is typing: only an even number of characters is valid!
        setLatitude({
          ...latitude,
          decimals: 0,
          ...decimalToDMS(0, true),
          dirty: false,
        })
        setLongitude({
          ...longitude,
          decimals: 0,
          ...decimalToDMS(0, false),
          dirty: false,
        })
      }
      setMGRS({ ...MGRS, dirty: false })
    } else if (latitude.dirty) {
      if (locationType === 'MGRS') {
        console.error('latitude was dirty was the user is editing MGRS')
        return
      } else if (locationType === 'deg/min/sec') {
        const decimals = dmsToDecimal(
          parseFloatOrElse(latitude.degrees, 0),
          parseFloatOrElse(latitude.minutes, 0),
          parseFloatOrElse(latitude.seconds, 0),
          null,
        )
        setLatitude({
          ...latitude,
          decimals,
          dirty: false,
        })
      } else {
        setLatitude({
          ...latitude,
          ...decimalToDMS(parseFloatOrElse(latitude.decimals, 0), true),
          dirty: false,
        })
      }
      computeMGRSFromLonlat()
    } else if (longitude.dirty) {
      if (locationType === 'MGRS') {
        console.error('longitude was dirty was the user is editing MGRS')
        return
      } else if (locationType === 'deg/min/sec') {
        const decimals = dmsToDecimal(
          parseFloatOrElse(longitude.degrees, 0),
          parseFloatOrElse(longitude.minutes, 0),
          parseFloatOrElse(longitude.seconds, 0),
          null,
        )
        setLongitude({
          ...longitude,
          decimals: decimals,
          dirty: false,
        })
      } else {
        setLongitude({
          ...longitude,
          ...decimalToDMS(parseFloatOrElse(longitude.decimals, 0), false),
          dirty: false,
        })
      }
      computeMGRSFromLonlat()
    }
  }

  const getElevation = async () => {
    let ele = null

    if (lonlatIsReasonable()) {
      const data = await getLocationElevation(
        parseFloatOrElse(latitude.decimals, 0),
        parseFloatOrElse(longitude.decimals, 0),
      )

      ele = data.results?.[0]?.elevation
    }

    /*
     * Zero may signify an error, but not necessarily:
     * all the oceans have zero elevation.
     * I don't see a good way to distinguish,
     * so don't report an error.
     */
    if (ele != null) {
      setElevation(ele)
    } else {
      setElevation(0)
    }
  }

  useEffect(() => {
    const needsElevation =
      debouncedLatitude.dirty || debouncedLongitude.dirty || debouncedMGRS.dirty
    computeLonlatFromLocationType()

    if (needsElevation) {
      getElevation()
    }
  }, [locationType, debouncedLatitude, debouncedLongitude, debouncedMGRS])

  useEffect(() => {
    if (selectedExerciseRequestLocation) {
      setElevation(selectedExerciseRequestLocation.elevation)
      setRegion(selectedExerciseRequestLocation.state_region_type_id)
      setTransmitterLocation(
        selectedExerciseRequestLocation.transmitter_location,
      )
      setTemplate(selectedExerciseRequestLocation.location_template_id)
      setRadius(selectedExerciseRequestLocation.radius)
      setElevation(selectedExerciseRequestLocation.elevation)
      const latitudeDecimals = dmsToDecimal(
        selectedExerciseRequestLocation.latitude_deg,
        selectedExerciseRequestLocation.latitude_min,
        selectedExerciseRequestLocation.latitude_sec,
        selectedExerciseRequestLocation.latitude_ns,
      )
      const longitudeDecimals = dmsToDecimal(
        selectedExerciseRequestLocation.longitude_deg,
        selectedExerciseRequestLocation.longitude_min,
        selectedExerciseRequestLocation.longitude_sec,
        selectedExerciseRequestLocation.longitude_ew,
      )

      setLatitude({
        degrees: correctDegreesGivenDirection(
          selectedExerciseRequestLocation.latitude_deg,
          selectedExerciseRequestLocation.latitude_ns,
        ),
        minutes: selectedExerciseRequestLocation.latitude_min,
        seconds: selectedExerciseRequestLocation.latitude_sec,
        decimals: latitudeDecimals,
        dirty: false,
      })
      setLongitude({
        degrees: correctDegreesGivenDirection(
          selectedExerciseRequestLocation.longitude_deg,
          selectedExerciseRequestLocation.longitude_ew,
        ),
        minutes: selectedExerciseRequestLocation.longitude_min,
        seconds: selectedExerciseRequestLocation.longitude_sec,
        decimals: longitudeDecimals,
        dirty: false,
      })
      computeMGRSFromLonlat()
    }
  }, [selectedExerciseRequestLocation])

  return (
    <SectionLayout
      sectionTitle="Location"
      btnText="Add Location"
      handleAdd={handleAdd}
      isDelete={isDelete}
      handleSave={handleSave}
      setValue={setValue}
      handleDelete={handleDelete}
      handleClose={handleClose}
      setIsDelete={setIsDelete}
      isLightMode={isLightMode}
      deleteText={'Are you sure you want to delete this location'}
      headerItems={
        <Select
          key="0.0.0.0"
          value={locationType}
          onChange={(evt) => setLocationType(evt.target.value)}
        >
          <MenuItem value="decimal">Decimal Degrees</MenuItem>
          <MenuItem value="deg/min/sec">Degrees Minutes Seconds</MenuItem>
          <MenuItem value="MGRS">MGRS</MenuItem>
        </Select>
      }
      isSelected={!!selectedExerciseRequestLocation}
      sidebarItems={
        <Box key="1.0" className={styles.detailedContainer}>
          {exerciseRequestLocations.map((location, i) => (
            <Box
              key={`1.0.${i}`}
              onClick={() => setSelectedExerciseRequestLocation(location)}
              className={
                location.id === selectedExerciseRequestLocation?.id
                  ? isLightMode
                    ? styles.item
                    : styles.selectedItem
                  : isLightMode
                    ? styles.lightItem
                    : styles.item
              }
            >
              {location.id === selectedExerciseRequestLocation?.id ? (
                <BookmarkIcon
                  className={styles.circle}
                  sx={{ color: isLightMode ? 'rgb(22, 119, 56)' : 'white' }}
                />
              ) : (
                <BookmarkBorderIcon className={styles.circle} />
              )}
              <Typography>{location.state_region_name}</Typography>
              <Box className={styles.details}>
                <Typography>Location:</Typography>
                <Typography>{location.transmitter_location}</Typography>
              </Box>
              <Box className={styles.details}>
                <Typography>Radius:</Typography>
                <Typography>{location.radius}</Typography>
              </Box>
            </Box>
          ))}
        </Box>
      }
      fieldItems={[
        <Box key="1.0" className={styles.section}>
          <Box className={styles.detailsSubSection}>
            <Box className={styles.fieldContainer}>
              <Typography className={styles.label}>
                Populate from template:
              </Typography>
              <SelectWithSearch
                width={350}
                value={locationTemplates
                  .map((template) => ({
                    label: template.template_name,
                    value: template.id,
                  }))
                  .find((location) => location.value === template)}
                label="Templates"
                handleChange={(value) => handleTemplateChange(Number(value))}
                options={locationTemplates.map((template) => ({
                  value: template.id,
                  label: template.template_name,
                }))}
              />
            </Box>
            <Box className={styles.fieldContainer}>
              <Typography className={styles.label}>
                State/Region/Country:
              </Typography>
              <SelectWithSearch
                width={350}
                value={stateRegions
                  .map((stateRegion) => ({
                    value: stateRegion.id,
                    label: stateRegion.name,
                  }))
                  .find((stateRegion) => stateRegion.value === region)}
                options={stateRegions.map((region) => ({
                  value: region.id,
                  label: region.name,
                }))}
                label="Regions"
                handleChange={(value) => setRegion(Number(value))}
              />
            </Box>
            <Box className={styles.fieldContainer}>
              <Typography className={styles.label}>
                City/Installation/Location:
              </Typography>
              <TextField
                className={styles.field}
                label="Location"
                value={transmitterLocation || ''}
                onChange={(evt) => setTransmitterLocation(evt.target.value)}
              />
            </Box>
            <Box className={styles.fieldContainer}>
              {locationType !== 'MGRS' && (
                <>
                  <Typography className={styles.label}>Latitude:</Typography>
                  {locationType === 'deg/min/sec' && (
                    <Box className={styles.locationContainer}>
                      <Box className={styles.details}>
                        <Typography className={styles.label}>
                          Degrees
                        </Typography>
                        <TextField
                          value={latitude.degrees || ''}
                          placeholder="Enter Value"
                          onChange={(evt) =>
                            setLatitude({
                              ...latitude,
                              degrees: evt.target.value,
                              dirty: true,
                            })
                          }
                          className={styles.locationField}
                        />
                      </Box>
                      <Box className={styles.details}>
                        <Typography className={styles.label}>
                          Minutes
                        </Typography>
                        <TextField
                          value={latitude.minutes || ''}
                          placeholder="Enter Value"
                          onChange={(evt) =>
                            setLatitude({
                              ...latitude,
                              minutes: evt.target.value,
                              dirty: true,
                            })
                          }
                          className={styles.locationField}
                        />
                      </Box>
                      <Box className={styles.details}>
                        <Typography className={styles.label}>
                          Seconds
                        </Typography>
                        <TextField
                          value={latitude.seconds || ''}
                          placeholder="Enter Value"
                          onChange={(evt) =>
                            setLatitude({
                              ...latitude,
                              seconds: evt.target.value,
                              dirty: true,
                            })
                          }
                          className={styles.locationField}
                        />
                      </Box>
                    </Box>
                  )}
                  {locationType === 'decimal' && (
                    <TextField
                      className={styles.field}
                      value={latitude.decimals || ''}
                      label="Latitude"
                      onChange={(evt) =>
                        setLatitude({
                          ...latitude,
                          decimals: evt.target.value,
                          dirty: true,
                        })
                      }
                      placeholder="Enter Value"
                    />
                  )}
                </>
              )}
            </Box>
            <Box className={styles.fieldContainer}>
              <Typography className={styles.label}>
                {locationType === 'MGRS' ? 'MGRS:' : 'Longitude:'}
              </Typography>
              {locationType === 'deg/min/sec' && (
                <Box className={styles.locationContainer}>
                  <Box className={styles.details}>
                    <Typography className={styles.label}>Degrees</Typography>
                    <TextField
                      value={longitude.degrees || ''}
                      placeholder="Enter Value"
                      onChange={(evt) =>
                        setLongitude({
                          ...longitude,
                          degrees: evt.target.value,
                          dirty: true,
                        })
                      }
                      className={styles.locationField}
                    />
                  </Box>
                  <Box className={styles.details}>
                    <Typography className={styles.label}>Minutes</Typography>
                    <TextField
                      value={longitude.minutes || ''}
                      placeholder="Enter Value"
                      onChange={(evt) =>
                        setLongitude({
                          ...longitude,
                          minutes: evt.target.value,
                          dirty: true,
                        })
                      }
                      className={styles.locationField}
                    />
                  </Box>
                  <Box className={styles.details}>
                    <Typography className={styles.label}>Seconds</Typography>
                    <TextField
                      value={longitude.seconds || ''}
                      placeholder="Enter Value"
                      onChange={(evt) =>
                        setLongitude({
                          ...longitude,
                          seconds: evt.target.value,
                          dirty: true,
                        })
                      }
                      className={styles.locationField}
                    />
                  </Box>
                </Box>
              )}
              {locationType === 'decimal' && (
                <TextField
                  className={styles.field}
                  value={longitude.decimals || ''}
                  placeholder="Enter Value"
                  label="Longitude"
                  onChange={(evt) =>
                    setLongitude({
                      ...longitude,
                      decimals: evt.target.value,
                      dirty: true,
                    })
                  }
                />
              )}
              {locationType === 'MGRS' && (
                <TextField
                  className={styles.field}
                  value={MGRS.value || ''}
                  onChange={(evt) =>
                    setMGRS({ value: evt.target.value, dirty: true })
                  }
                  placeholder="Enter Value"
                />
              )}
            </Box>
            <Box className={styles.fieldContainer}>
              <Typography className={styles.label}>Radius (km):</Typography>
              <TextField
                className={styles.field}
                value={radius || ''}
                label="Radius"
                onChange={(evt) => setRadius(Number(evt.target.value))}
                placeholder="Enter Value"
              />
            </Box>
            <Box className={styles.fieldContainer}>
              <Typography className={styles.label}>Elevation:</Typography>
              <TextField
                className={styles.field}
                label="Elevation"
                placeholder="Enter Value"
                value={elevation || ''}
                onChange={(evt) => setElevation(Number(evt.target.value))}
              />
            </Box>
          </Box>
        </Box>,
      ]}
    />
  )
}

export default LocationSection

import { Dispatch, FC, SetStateAction } from 'react'
import { Box, Button, Typography } from '@mui/material'
import ConfirmationModal from '../../Modals/ConfirmationModal'

import styles from './styles.module.scss'

interface IProps {
  btnText: string
  isDelete: boolean
  deleteText: string
  handleAdd: () => void
  setValue: Dispatch<SetStateAction<number>>
  handleSave: () => void
  handleClose: () => void
  setIsDelete: Dispatch<SetStateAction<boolean>>
  handleDelete: () => void
  handleNavigate?: () => void
  isSelected: boolean
  fieldItems: JSX.Element[]
  isLightMode: boolean
  sectionTitle: string
  sidebarItems: JSX.Element
  headerItems: JSX.Element
  mapComponent?: JSX.Element
}

const SectionLayout: FC<IProps> = ({
  btnText,
  isDelete,
  deleteText,
  handleAdd,
  setValue,
  handleSave,
  handleClose,
  handleDelete,
  setIsDelete,
  handleNavigate,
  isSelected,
  isLightMode,
  sectionTitle,
  sidebarItems,
  headerItems,
  mapComponent,
  fieldItems,
}) => {
  return (
    <Box className={styles.locationSection}>
      <Box className={styles.content}>
        <Box className={styles.leftContainer}>
          <Button
            key="0"
            className={styles.addBtn}
            variant="outlined"
            onClick={handleAdd}
            style={isLightMode ? {} : { backgroundColor: 'green' }}
          >
            {btnText}
          </Button>
          {sidebarItems}
          {mapComponent && isSelected && (
            <Box key="2" className={styles.mapContainer}>{mapComponent}</Box>
          )}
        </Box>
        <Box className={styles.rightContainer}>
          {isSelected && (
            <>
              <Box className={styles.sectionContainer}>
                <Box key="0.0" className={styles.titleContainer}>
                  <Typography className={styles.title}>
                    {sectionTitle}
                  </Typography>
                  {headerItems}
                  <Box key="0.0.1.0" className={styles.actions}>
                    <Button
                      className={styles.saveBtn}
                      variant="outlined"
                      onClick={() => handleSave()}
                      style={isLightMode ? {} : { backgroundColor: 'green' }}
                    >
                      Save
                    </Button>
                    <Button
                      className={styles.saveBtn}
                      variant="outlined"
                      onClick={() => setIsDelete(true)}
                      style={isLightMode ? {} : { backgroundColor: 'red' }}
                    >
                      Delete
                    </Button>
                  </Box>
                </Box>
                {fieldItems.map((elem) => elem)}
              </Box>
              {sectionTitle !== 'Equipment' && (
                <Box className={styles.nextBtnContainer}>
                  <Button
                    variant="outlined"
                    className={styles.nextBtn}
                    onClick={() => {
                      setValue((value) => value + 1)
                      handleSave()
                    }}
                    style={isLightMode ? {} : { backgroundColor: 'green' }}
                  >
                    Next
                  </Button>
                </Box>
              )}
              {sectionTitle === 'Equipment' && (
                <Box className={styles.nextBtnContainer}>
                  <Button
                    variant="outlined"
                    className={styles.nextBtn}
                    onClick={handleNavigate}
                  >
                    Submit
                  </Button>
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
      {isDelete && (
        <ConfirmationModal
          onClose={handleClose}
          color={'error'}
          onConfirm={handleDelete}
          btnText={'Delete'}
          text={deleteText}
        />
      )}
    </Box>
  )
}

export default SectionLayout

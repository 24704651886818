import { axiosInstance } from './axios.service'
import { IResponse } from '../types/response.type'
import { AuthEndpoints } from '../types/endpoint.type'
import { IUser } from '../types/auth.type'
import axios from 'axios'

export const getUserByEmail = async (
  email: string,
  token: string,
  username: string,
): Promise<IResponse<IUser>> => {
  try {
    const data = await axios.post(
      `${window.config.appBaseUrl}/${AuthEndpoints.GET}`,
      {
        email,
        username,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

import axios from 'axios'
import { getAccessTokenSilentlyType } from '../types/auth.type'

export const axiosInstance = axios.create({
  baseURL: `${window.config.appBaseUrl}/api`,
})

export const configureAxiosAuth = (
  getAccessTokenSilently: getAccessTokenSilentlyType,
) => {
  axiosInstance.interceptors.request.use(
    async (config) => {
      try {
        const token = await getAccessTokenSilently()
        config.headers.Authorization = `Bearer ${token}`
      } catch (error) {
        console.error('Error fetching access token:', error)
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    },
  )
}

export enum AuthEndpoints {
  GET = 'auth/',
}

export enum PlaybookEndpoints {
  GET_ALL = 'playbooks',
  GET_ONE = 'playbooks/',
  UPDATE = 'playbooks/',
  CREATE = 'playbooks/',
  DELETE = 'playbooks/',
  RUN = 'playbooks/run/',
  STOP = 'playbooks/stop/',
  DOWNLOAD_PDF = 'playbooks/pdf/download',
}

export enum AdditionalActionsEndpoints {
  GET = 'additional-actions/',
}

export enum EdgeEndpoints {
  GET = 'edges/',
  CREATE = 'edges/',
}

export enum NodeEndpoints {
  GET = 'nodes/',
  CREATE = 'nodes/',
  UPDATE = 'nodes/',
}

export enum SchedulerEndpoints {
  GET = 'schedulers/',
  CREATE = 'schedulers/',
  UPDATE = 'schedulers/',
  DELETE = 'schedulers/',
}

export enum RequestIdEndpoints {
  GET_TABLES = 'requests/tables',
  GET_VALUES = 'requests/values',
  GET_COLUMNS = 'requests/columns',
  GET_LOCATIONS = 'requests/locations',
}

export enum FrequencyAssignmentEndpoints {
  GET = '/frequency-assignments',
}

export enum ExerciseRequestFormEndpoints {
  GET_DEVICES = 'exercise-request-form/devices',
  POWER_RANGES = 'exercise-request-form/power-ranges',
  GET_EXERCISES = 'exercise-request-form/exercises',
  STATION_CLASS = 'exercise-request-form/station-classes',
  EMISSION_TYPES = 'exercise-request-form/emission-types',
  GET_USAGE_TIMES = 'exercise-request-form/usage-time',
  EXERCISE_REQUEST = 'exercise-request-form/exercise-request',
  GET_STATE_REGIONS = 'exercise-request-form/state-regions',
  GET_NOMENCLATURES = 'exercise-request-form/nomenclatures',
  GET_MANUFACTURERS = 'exercise-request-form/manufacturers',
  GET_DEVICE_ANTENNAS = 'exercise-request-form/device-antennas',
  GET_OPERATING_UNITS = 'exercise-request-form/operating-units',
  GET_MAJOR_FUNCTIONS = 'exercise-request-form/major-functions',
  GET_EXERCISE_REQUESTS = 'exercise-request-form/exercise-requests',
  GET_NUMBER_OF_STATIONS = 'exercise-request-form/number-of-stations',
  GET_DETAILED_FUNCTIONS = 'exercise-request-form/detailed-functions',
  GET_LOCATION_TEMPLATES = 'exercise-request-form/location-templates',
  GET_USAGE_TIME_SERVICES = 'exercise-request-form/usage-time-service',
  GET_OPERATING_SUB_UNITS = 'exercise-request-form/operating-sub-units',
  GET_FREQUENCY_TEMPLATES = 'exercise-request-form/frequency-templates',
  EXERCISE_REQUEST_DEVICE = 'exercise-request-form/exercise-request-device',
  GET_CLASSIFICATION_TYPES = 'exercise-request-form/classification-types',
  STATION_CLASS_DESCRIPTION = 'exercise-request-form/station-class-descriptions',
  GET_RADAR_TUNABILITY_TYPES = 'exercise-request-form/radar-tunability-types',
  GET_INTERMEDIATE_FUNCTIONS = 'exercise-request-form/intermediate-functions',
  EXERCISE_REQUEST_LOCATIONS = 'exercise-request-form/exercise-request-location',
  EXERCISE_REQUEST_EQUIPMENTS = 'exercise-request-form/exercise-request-equipment',
  EXERCISE_REQUEST_FREQUENCIES = 'exercise-request-form/exercise-request-frequency',
  EXERCISE_REQUEST_STATION_CLASS = 'exercise-request-form/exercise-request-station-class',
  EXERCISE_REQUEST_DEVICE_ANTENNA = 'exercise-request-form/exercise-request-device-antenna',
  EMISSION_DESIGNATOR_SIMPLE_TYPES = 'exercise-request-form/emission-designator-simple-types',
  EXERCISE_REQUEST_FREQUENCIES_SINGLE = 'exercise-request-form/exercise-request-frequency-single',
  GET_DEVICE_TRANSMITTERS = 'exercise-request-form/device-transmitters',
}

export enum HeatmapEndpoints {
  CREATE_HEATMAP = '/cloud-rf/heatmap',
  CREATE_SIMULATION = '/cloud-rf/simulate',
}

export enum FrequencyAssignmentEndpoints {
  GET_FREQUENCY_ASSIGNMENTS = '/frequency-assignments',
}

export enum SpectrumManagerEndpoints {
  SFAF = '/spectrum-manager/sfaf',
  DEVICE = '/spectrum-manager/device',
  HOPSET = '/spectrum-manager/hopsets',
  LICENSE = '/spectrum-manager/licenses',
  EXERCISE = '/spectrum-manager/exercise',
  PLATFORMS = '/spectrum-manager/platform',
  FREQUENCY = '/spectrum-manager/frequency',
  EQUIPMENT = '/spectrum-manager/equipment',
  SFAF_ANTENNAS = '/spectrum-manager/sfaf-antennas',
  SFAF_CONTENTS = '/spectrum-manager/sfaf-contents',
  OPERATING_UNIT = 'spectrum-manager/operating-unit',
  DEVICE_ANTENNA = '/spectrum-manager/device-antennas',
  SFAF_EQUIPMENTS = '/spectrum-manager/sfaf-equipments',
  FREQUENCY_PLANS = '/spectrum-manager/frequency-plans',
  SFAF_FREQUENCIES = '/spectrum-manager/sfaf-frequencies',
  AGENCY_OVERRIDE = '/spectrum-manager/agency-override',
  FREQUENCY_REQUEST = '/spectrum-manager/frequency-request',
  EXERCISE_REQUESTS = '/spectrum-manager/exercise-request',
  ARC_DIAGRAM_NODES = 'spectrum-manager/arc-diagram-nodes',
  DEVICE_TRANSMITTER = '/spectrum-manager/device-transmitters',
  ASSIGNED_FREQUENCY = '/spectrum-manager/assigned-frequency',
  LOCATION_TEMPLATES = '/spectrum-manager/location-template',
  NOMINATE_FREQUENCY = '/spectrum-manager/nominate-frequency',
  UPDATE_RADIUS_CACHE = '/spectrum-manager/radius-cache',
  DEVICE_ANTENNA_MODE = '/spectrum-manager/device-antenna-mode',
  SFAF_STATION_CLASSES = '/spectrum-manager/sfaf-station-classes',
  OPEN_FREQUENCY_REQUEST = '/spectrum-manager/open-frequency-requests',
  FREQUENCY_POOL_EQUIPMENT = '/spectrum-manager/frequency-pool-equipment',
  DEVICE_TRANSMITTER_PULSE = '/spectrum-manager/device-transmitter-pulse',
  SFAF_EXCLUDED_FREQUENCIES = '/spectrum-manager/sfaf-excluded-frequencies',
  FREQUENCY_POOL_113_114_115 = '/spectrum-manager/frequency-pool-113-114-115',
}

interface IHeatmapParams {
  lat: number
  lon: number
  frq: number
  rad: number
  alt?: number // elevation above ground (not sea level) (meters)
  txw?: number // transmission power (watts)
}

// N.B. If you change this, you should change the same function on the backend as well.
const prepareHeatmapParams = ({ lat, lon, frq, rad, alt, txw }: IHeatmapParams) => ({
  site: 'A1',
  network: 'Testing',
  transmitter: {
    lat,
    lon,
    alt: alt == null ? 2 : alt,
    frq,
    txw: txw || 1,
    bwi: 1,
  },
  receiver: {
    lat: 0,
    lon: 0,
    alt: 2,
    rxg: 3,
    rxs: -100,
  },
  antenna: {
    txg: 1.5,
    txl: 0,
    ant: 0,
    azi: 90,
    tlt: 2,
    hbw: 90,
    vbw: 20,
    pol: 'v',
  },
  environment: {
    clt: 'Minimal.clt',
    elevation: 1,
    landcover: 0,
    buildings: 0,
    obstacles: 0,
  },
  output: {
    units: 'm', // Choices are m (meters above ground level, kilometer distances), m_amsl (meters above sea level, kilometer distances), f (feet above ground level, mile distances), f_amsl (feet above sea level, mile distances). Default is m. See https://cloudrf.com/documentation/developer/#/Create/area
    col: 'RAINBOW.dBm',
    out: 2,
    ber: 2,
    mod: 7,
    nf: -100,
    res: 10,
    rad,
  },
})

export default prepareHeatmapParams

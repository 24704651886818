import {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'
import {
  Box,
  Button,
  Checkbox,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import BookmarkIcon from '@mui/icons-material/Bookmark'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import { ExerciseRequestContext } from '../../../../contexts/exercise-request.context'
import {
  updateFrequencyById,
  createExerciseFrequency,
  getIntermediateFunctionsByMajorFunctionId,
  getDetailedFunctionsByIntermediateFunctionId,
  deleteFrequencyById,
  getAllSingleFrequencies,
  getAllFrequencyTemplates,
  deleteSingleFrequency,
  createFrequencySingle,
  updateSingleFrequency,
} from '../../../../services/exercise-request-form.service'
import SectionLayout from '../SectionLayout'
import {
  IDetailedFunction,
  IFrequencyTemplate,
  IIntermediateFunction,
  IExerciseRequestFrequency,
  IExerciseRequestFrequencySingle,
} from '../../../../types/exercise.type'
import { useToast } from '../../../../contexts/toast.context'
import { SelectComponent } from '../../../shared/SelectComponent'
import { SelectWithSearch } from '../../../shared/SearchSelect'
import {
  createAssignedFrequency,
  getAssignedFrequenciesByFrequencyId,
} from '../../../../services/spectrum-manager.service'
import { calculateAzimuth, isFrequencyValid } from '../../../../utils/functions'

import styles from '../SectionLayout/styles.module.scss'

interface IProps {
  setValue: Dispatch<SetStateAction<number>>
  isLightMode: boolean
}

const FrequencySection: FC<IProps> = ({ isLightMode, setValue }) => {
  const [frequency, setFrequency] = useState<IExerciseRequestFrequency | null>(
    null,
  )
  const [azimuth, setAzimuth] = useState(0)
  const [actualNumberOfFrequencies, setActualNumberOfFrequencies] = useState(0)
  const [isDelete, setIsDelete] = useState(false)
  const [frequencySingle, setFrequencySingle] =
    useState<IExerciseRequestFrequencySingle | null>(null)
  const [frequencyTemplates, setFrequencyTemplates] = useState<
    IFrequencyTemplate[]
  >([])
  const [detailedFunctions, setDetailedFunctions] = useState<
    IDetailedFunction[]
  >([])
  const [singleFrequencies, setSingleFrequencies] = useState<
    IExerciseRequestFrequencySingle[]
  >([])
  const [intermediateFunctions, setIntermediateFunctions] = useState<
    IIntermediateFunction[]
  >([])

  const {
    usageTimes,
    majorFunctions,
    usageTimeServices,
    selectedExerciseRequest,
    exerciseRequestLocations,
    exerciseRequestFrequencies,
    setExerciseRequestFrequencies,
    getExerciseRequestFrequencies,
    selectedExerciseRequestFrequency,
    setSelectedExerciseRequestFrequency,
  } = useContext(ExerciseRequestContext)

  const { showToast } = useToast()

  const handleClose = () => {
    setIsDelete(false)
  }

  const handleAdd = async () => {
    if (selectedExerciseRequest) {
      const data = await createExerciseFrequency(selectedExerciseRequest.id)

      if (data.success && data.data) {
        setExerciseRequestFrequencies([
          ...exerciseRequestFrequencies,
          data.data,
        ])
        showToast('success', 'Frequency created successfully')

        const newSingleFrequency = await createFrequencySingle(
          data.data.id,
          false,
        )

        if (newSingleFrequency.data && data.success) {
          setSingleFrequencies([...singleFrequencies, newSingleFrequency.data])
          setFrequencySingle(newSingleFrequency.data)
        }

        const newAssignedFrequency = await createAssignedFrequency(data.data.id)

        if (newAssignedFrequency.success) {
          setActualNumberOfFrequencies(1)
        }
      }
    }
  }

  const handleSave = async () => {
    if (!isFrequencyValid(frequencySingle)) {
      showToast('error', 'Please choose the frequency band')
      return
    }

    if (frequency) {
      const data = await updateFrequencyById(frequency.id, {
        hopset: '',
        saturn: false,
        platform: frequency.platform || '',
        locationId: frequency.exercise_request_location_id,
        usageTimeId: frequency.usage_time_id || null,
        percentTime: 0,
        majorFunctionId: frequency.major_function_id || null,
        receiveLocationId:
          frequency.receive_exercise_request_location_id || null,
        usageTimeServiceId: frequency.usage_time_service_id || null,
        detailedFunctionId: frequency.detailed_function_id || null,
        numberOfFrequencies: frequency.number_of_frequencies,
        supplementaryDetails: frequency.supplementary_details,
        intermediateFunctionId: frequency.intermediate_function_id || null,
        isDifferentReceiveLocation: frequency.is_different_receive_location,
      })

      if (data.success && data.data) {
        setSelectedExerciseRequestFrequency(data.data)

        showToast('success', 'Frequency updated successfully')
        getExerciseRequestFrequencies()

        for (const frequencySingle of singleFrequencies) {
          if (!frequencySingle.is_exclude) {
            continue
          }

          await updateSingleFrequency(frequencySingle.id, {
            ...frequencySingle,
          })
        }

        if (frequencySingle) {
          await updateSingleFrequency(frequencySingle.id, {
            ...frequencySingle,
          })
        }

        // Determine the difference between the specified number of frequencies and the existing assigned frequencies
        const freqDiff =
          frequency?.number_of_frequencies - actualNumberOfFrequencies

        for (let i = 0; i < freqDiff; i++) {
          await createAssignedFrequency(frequency.id)
        }
      } else {
        showToast('error', data.message)
      }
    }

    handleClose()
  }

  const handleFrequencyChange = (
    key: string,
    value: string | number | boolean | null,
  ) => {
    if (frequency) {
      setFrequency({ ...frequency, [key]: value })
    }
  }

  const handleDelete = async () => {
    if (selectedExerciseRequestFrequency) {
      const data = await deleteFrequencyById(
        selectedExerciseRequestFrequency.id,
      )

      if (data.success) {
        setExerciseRequestFrequencies(
          exerciseRequestFrequencies.filter(
            (frequency) => frequency.id !== selectedExerciseRequestFrequency.id,
          ),
        )
        showToast('success', 'Frequency removed successfully')

        setSelectedExerciseRequestFrequency(null)
      }
    }

    handleClose()
  }

  const getIntermediateFunctions = async () => {
    if (frequency?.major_function_id) {
      const data = await getIntermediateFunctionsByMajorFunctionId(
        frequency.major_function_id,
      )

      if (data.success && data.data) {
        setIntermediateFunctions(data.data)
      }
    }
  }

  const getFrequencyTemplates = async () => {
    const data = await getAllFrequencyTemplates()

    if (data.success && data.data) {
      setFrequencyTemplates(data.data)
    }
  }

  const getSingleFrequencies = async () => {
    if (selectedExerciseRequestFrequency) {
      const data = await getAllSingleFrequencies(
        selectedExerciseRequestFrequency.id,
      )

      if (data.data && data.success) {
        setSingleFrequencies(data.data)

        const notExcludedFreqSingle = data.data.find(
          (freqSingle) => !freqSingle.is_exclude,
        )

        if (notExcludedFreqSingle) {
          setFrequencySingle(notExcludedFreqSingle)
        }
      }
    }
  }

  const getDetailedFunctions = async () => {
    if (frequency?.intermediate_function_id) {
      const data = await getDetailedFunctionsByIntermediateFunctionId(
        frequency.intermediate_function_id,
      )

      if (data.success && data.data) {
        setDetailedFunctions(data.data)
      }
    }
  }

  const handleAddSingleFrequency = async () => {
    if (frequency) {
      const data = await createFrequencySingle(frequency.id, true)

      if (data.data && data.success) {
        setSingleFrequencies([...singleFrequencies, data.data])
      }
    }
  }

  const handleSingleFrequencyDelete = async (id: number) => {
    const data = await deleteSingleFrequency(id)

    if (data.success) {
      setSingleFrequencies(
        singleFrequencies.filter(
          (frequencySingle) => frequencySingle.id !== id,
        ),
      )
    }
  }

  const handleSingleFrequencyUpdate = async (
    id: number,
    key: string,
    value: string | number | boolean,
  ) => {
    const updatedSingleFrequencies = singleFrequencies.map((frequencySingle) =>
      frequencySingle.id === id
        ? { ...frequencySingle, [key]: value }
        : frequencySingle,
    )

    setSingleFrequencies(updatedSingleFrequencies)
  }

  const getAssignedFrequencies = async () => {
    if (selectedExerciseRequestFrequency) {
      const data = await getAssignedFrequenciesByFrequencyId(
        selectedExerciseRequestFrequency.id,
      )

      if (data.data) {
        setActualNumberOfFrequencies(data.data.length)
      }
    }
  }

  const handleSingleFrequencyChange = async (
    key: string,
    value: string | null | boolean | number,
  ) => {
    if (key === 'frequency_template_id') {
      const template = frequencyTemplates.find(
        (template) => template.id === value,
      )

      if (template && frequencySingle) {
        setFrequencySingle({
          ...frequencySingle,
          start_freq: template.start_freq,
          start_indicator: template.start_indicator,
          end_freq: template.end_freq,
          end_indicator: template.end_indicator,
          frequency_template_id: Number(value),
        })
      }

      return
    }

    const updatedFrequencySingle = frequencySingle
      ? frequencySingle
      : ({} as IExerciseRequestFrequencySingle)

    setFrequencySingle({ ...updatedFrequencySingle, [key]: value })
    setSingleFrequencies(
      singleFrequencies.map((singleFreq) =>
        singleFreq.is_exclude ? singleFreq : { ...singleFreq, [key]: value },
      ),
    )
  }

  useEffect(() => {
    if (frequency?.major_function_id) {
      getIntermediateFunctions()
    }
  }, [frequency?.major_function_id])

  useEffect(() => {
    if (frequency?.intermediate_function_id) {
      getDetailedFunctions()
    }
  }, [frequency?.intermediate_function_id])

  useEffect(() => {
    if (selectedExerciseRequestFrequency) {
      getFrequencyTemplates()
      getSingleFrequencies()
      getAssignedFrequencies()

      if (
        !selectedExerciseRequestFrequency.exercise_request_location_id &&
        exerciseRequestLocations.length
      ) {
        setFrequency({
          ...selectedExerciseRequestFrequency,
          exercise_request_location_id: exerciseRequestLocations[0].id,
        })
      } else {
        setFrequency(selectedExerciseRequestFrequency)
      }
    }
  }, [selectedExerciseRequestFrequency])

  useEffect(() => {
    if (frequency) {
      const sourceLocation = exerciseRequestLocations.find(
        (elem) => elem.id === frequency.exercise_request_location_id,
      )

      const targetLocation = exerciseRequestLocations.find(
        (elem) => elem.id === frequency.receive_exercise_request_location_id,
      )

      if (sourceLocation && targetLocation) {
        const azimuth = calculateAzimuth(
          sourceLocation.latitude_deg,
          sourceLocation.longitude_deg,
          targetLocation.latitude_deg,
          targetLocation.longitude_deg,
        )

        setAzimuth(azimuth)
      }
    }
  }, [
    frequency?.exercise_request_location_id,
    frequency?.receive_exercise_request_location_id,
  ])

  return (
    <SectionLayout
      sectionTitle="Frequency"
      btnText="Add Frequency"
      handleAdd={handleAdd}
      isDelete={isDelete}
      handleClose={handleClose}
      handleSave={handleSave}
      handleDelete={handleDelete}
      setIsDelete={setIsDelete}
      isLightMode={isLightMode}
      setValue={setValue}
      deleteText={'Are you sure you want to delete this frequency?'}
      headerItems={
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box className={styles.item}>
            <SelectWithSearch
              width={300}
              value={exerciseRequestLocations
                .map((location) => ({
                  label: location.transmitter_location,
                  value: location.id,
                }))
                .find(
                  (elem) =>
                    elem.value === frequency?.exercise_request_location_id,
                )}
              label="Location"
              handleChange={(value) =>
                handleFrequencyChange(
                  'exercise_request_location_id',
                  Number(value),
                )
              }
              options={exerciseRequestLocations.map((location) => ({
                value: location.id,
                label: location.transmitter_location,
              }))}
            />
            <Checkbox
              checked={Boolean(frequency?.is_different_receive_location)}
              onChange={(_, checked) =>
                handleFrequencyChange('is_different_receive_location', checked)
              }
            />
            <Typography>Different receiver location</Typography>
          </Box>
          {frequency?.is_different_receive_location && (
            <Box className={styles.item}>
              <SelectComponent
                items={exerciseRequestLocations
                  .filter(
                    (elem) =>
                      elem.id !== frequency?.exercise_request_location_id,
                  )
                  .map((elem) => ({
                    value: elem.id,
                    displayValue: elem.transmitter_location,
                  }))}
                label="Receiving Location"
                value={frequency?.receive_exercise_request_location_id}
                className={styles.field}
                handleChange={(value) =>
                  handleFrequencyChange(
                    'receive_exercise_request_location_id',
                    Number(value),
                  )
                }
              />
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography>Azimuth</Typography>
                <TextField
                  className={styles.field}
                  label="Azimuth"
                  value={azimuth}
                  onChange={(evt) => setAzimuth(Number(evt.target.value))}
                />
              </Box>
            </Box>
          )}
        </Box>
      }
      isSelected={!!selectedExerciseRequestFrequency}
      sidebarItems={
        <Box className={styles.detailedContainer}>
          {exerciseRequestFrequencies.map((frequency) => (
            <Box
              onClick={() => setSelectedExerciseRequestFrequency(frequency)}
              className={
                frequency.id === selectedExerciseRequestFrequency?.id
                  ? isLightMode
                    ? styles.item
                    : styles.selectedItem
                  : isLightMode
                    ? styles.lightItem
                    : styles.item
              }
            >
              {frequency.id === selectedExerciseRequestFrequency?.id ? (
                <BookmarkIcon
                  className={styles.circle}
                  sx={{ color: isLightMode ? 'rgb(22, 119, 56)' : 'white' }}
                />
              ) : (
                <BookmarkBorderIcon className={styles.circle} />
              )}
              <Box className={styles.details}>
                <Typography>Location:</Typography>
                <Typography className={styles.info}>
                  {
                    exerciseRequestLocations.find(
                      (location) =>
                        location.id === frequency.exercise_request_location_id,
                    )?.transmitter_location
                  }
                </Typography>
              </Box>
              <Box className={styles.details}>
                <Typography>Platform:</Typography>
                <Typography className={styles.info}>
                  {frequency.platform}
                </Typography>
              </Box>
              <Box className={styles.details}>
                <Typography>Major Fn:</Typography>
                <Typography className={styles.info}>
                  {
                    majorFunctions.find(
                      (elem) => elem.id === frequency.major_function_id,
                    )?.name
                  }
                </Typography>
              </Box>
              <Box className={styles.details}>
                <Typography>Detailed Fn:</Typography>
                <Typography className={styles.info}>
                  {
                    detailedFunctions.find(
                      (elem) => elem.id === frequency.detailed_function_id,
                    )?.name
                  }
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      }
      fieldItems={
        frequency
          ? [
              <Box className={styles.section}>
                <Box className={styles.titleContainer}>
                  <Typography className={styles.title}>Emissions</Typography>
                </Box>
                <Box className={styles.subSection}>
                  <Box className={styles.singleFrequency}>
                    <Typography className={styles.label}>
                      Frequency(or within frequency band)
                    </Typography>
                    <SelectWithSearch
                      width={200}
                      isDisabled={frequencySingle?.is_custom}
                      value={frequencyTemplates
                        .map((template) => ({
                          label: template.display,
                          value: template.id,
                        }))
                        .find(
                          (elem) =>
                            elem.value ===
                            frequencySingle?.frequency_template_id,
                        )}
                      label="Frequency Band"
                      handleChange={(value) =>
                        handleSingleFrequencyChange(
                          'frequency_template_id',
                          Number(value),
                        )
                      }
                      options={frequencyTemplates.map((template) => ({
                        value: template.id,
                        label: template.display,
                      }))}
                    />
                    <Box className={styles.details}>
                      <Checkbox
                        sx={{ display: 'none' }}
                        checked={Boolean(frequencySingle?.is_ref_frequency)}
                        onChange={(_, checked) =>
                          handleSingleFrequencyChange(
                            'is_ref_frequency',
                            checked,
                          )
                        }
                      />
                      <Typography>Custom</Typography>
                      <Checkbox
                        checked={frequencySingle?.is_custom}
                        onChange={(_, checked) =>
                          handleSingleFrequencyChange('is_custom', checked)
                        }
                      />
                      <Select
                        className={styles.field}
                        value={frequencySingle?.start_indicator || ''}
                        disabled={!frequencySingle?.is_custom}
                        onChange={(evt) =>
                          handleSingleFrequencyChange(
                            'start_indicator',
                            evt.target.value,
                          )
                        }
                      >
                        {['K', 'M', 'G', 'T'].map((measure) => (
                          <MenuItem key={measure} value={measure}>
                            {measure}
                          </MenuItem>
                        ))}
                      </Select>
                      <TextField
                        className={styles.field}
                        value={frequencySingle?.start_freq}
                        placeholder="Enter Value"
                        disabled={!frequencySingle?.is_custom}
                        onChange={(evt) =>
                          handleSingleFrequencyChange(
                            'start_freq',
                            Number(evt.target.value),
                          )
                        }
                      />
                      <Select
                        className={styles.field}
                        value={frequencySingle?.end_indicator || ''}
                        disabled={!frequencySingle?.is_custom}
                        onChange={(evt) =>
                          handleSingleFrequencyChange(
                            'end_indicator',
                            evt.target.value,
                          )
                        }
                      >
                        {['K', 'M', 'G', 'T'].map((measure) => (
                          <MenuItem key={measure} value={measure}>
                            {measure}
                          </MenuItem>
                        ))}
                      </Select>
                      <TextField
                        value={frequencySingle?.end_freq}
                        placeholder="Enter Value"
                        className={styles.field}
                        disabled={!frequencySingle?.is_custom}
                        onChange={(evt) =>
                          handleSingleFrequencyChange(
                            'end_freq',
                            Number(evt.target.value),
                          )
                        }
                      />
                    </Box>
                  </Box>
                </Box>
                <Box className={styles.subSection}>
                  {singleFrequencies
                    .filter((freqSingle) => freqSingle.is_exclude)
                    .map((frequencySingle, index) => (
                      <Box
                        className={styles.singleFrequency}
                        key={frequencySingle.id}
                      >
                        <Typography>{index + 1}.</Typography>
                        <Box className={styles.details}>
                          <Select sx={{ display: 'none' }}>
                            {frequencyTemplates.map((template) => (
                              <MenuItem key={template.id} value={template.id}>
                                {template.display}
                              </MenuItem>
                            ))}
                          </Select>
                          <Checkbox
                            sx={{ display: 'none' }}
                            checked={Boolean(frequencySingle.is_ref_frequency)}
                            onChange={(_, checked) =>
                              handleSingleFrequencyUpdate(
                                frequencySingle.id,
                                'is_ref_frequency',
                                checked,
                              )
                            }
                          />
                          <Checkbox
                            sx={{ display: 'none' }}
                            checked={Boolean(frequencySingle.is_custom)}
                            onChange={(_, checked) =>
                              handleSingleFrequencyUpdate(
                                frequencySingle.id,
                                'is_custom',
                                checked,
                              )
                            }
                          />
                          <Typography>Band</Typography>
                          <Checkbox
                            checked={Boolean(frequencySingle.is_band)}
                            onChange={(_, checked) =>
                              handleSingleFrequencyUpdate(
                                frequencySingle.id,
                                'is_band',
                                checked,
                              )
                            }
                          />
                          <Select
                            className={styles.field}
                            defaultValue="K"
                            value={frequencySingle.start_indicator}
                            onChange={(evt) =>
                              handleSingleFrequencyUpdate(
                                frequencySingle.id,
                                'start_indicator',
                                evt.target.value,
                              )
                            }
                          >
                            {['K', 'M', 'G', 'T'].map((measure) => (
                              <MenuItem key={measure} value={measure}>
                                {measure}
                              </MenuItem>
                            ))}
                          </Select>
                          <TextField
                            className={styles.field}
                            value={frequencySingle.start_freq}
                            placeholder="Enter Value"
                            onChange={(evt) =>
                              handleSingleFrequencyUpdate(
                                frequencySingle.id,
                                'start_freq',
                                Number(evt.target.value),
                              )
                            }
                          />
                          <Select
                            className={styles.field}
                            defaultValue="K"
                            value={frequencySingle.end_indicator}
                            onChange={(evt) =>
                              handleSingleFrequencyUpdate(
                                frequencySingle.id,
                                'end_indicator',
                                evt.target.value,
                              )
                            }
                          >
                            {['K', 'M', 'G', 'T'].map((measure) => (
                              <MenuItem key={measure} value={measure}>
                                {measure}
                              </MenuItem>
                            ))}
                          </Select>
                          <TextField
                            value={frequencySingle.end_freq}
                            placeholder="Enter Value"
                            className={styles.field}
                            onChange={(evt) =>
                              handleSingleFrequencyUpdate(
                                frequencySingle.id,
                                'end_freq',
                                Number(evt.target.value),
                              )
                            }
                          />
                          <Button
                            color="error"
                            variant="outlined"
                            onClick={() =>
                              handleSingleFrequencyDelete(frequencySingle.id)
                            }
                          >
                            <DeleteIcon sx={{ color: 'white' }} />
                          </Button>
                        </Box>
                      </Box>
                    ))}
                  <Button
                    color="success"
                    variant="outlined"
                    className={styles.addFreq}
                    onClick={handleAddSingleFrequency}
                  >
                    Add Excluded Freq
                  </Button>
                </Box>
              </Box>,
              <Box className={styles.section}>
                <Box className={styles.titleContainer}>
                  <Typography className={styles.title}>
                    Frequency Characteristics
                  </Typography>
                </Box>
                <Box className={styles.subSection}>
                  <Box className={styles.fieldContainer}>
                    <Typography className={styles.label}>Platform:</Typography>
                    <TextField
                      value={frequency?.platform || ''}
                      label="Platform"
                      className={styles.field}
                      onChange={(evt) =>
                        handleFrequencyChange('platform', evt.target.value)
                      }
                    />
                  </Box>
                  <Box className={styles.fieldContainer}>
                    <Typography className={styles.label}>
                      Number of Frequencies:
                    </Typography>
                    <TextField
                      value={frequency?.number_of_frequencies || ''}
                      label="Number of Frequencies"
                      type="number"
                      inputProps={{ min: actualNumberOfFrequencies }}
                      className={styles.field}
                      onChange={(evt) =>
                        handleFrequencyChange(
                          'number_of_frequencies',
                          Number(evt.target.value),
                        )
                      }
                    />
                  </Box>
                  <Box className={styles.fieldContainer}>
                    <Typography className={styles.label}>
                      Major function:
                    </Typography>
                    <SelectComponent
                      items={majorFunctions.map((elem) => ({
                        value: elem.id,
                        displayValue: elem.name,
                      }))}
                      label="Major FN"
                      value={frequency?.major_function_id}
                      className={styles.field}
                      handleChange={(value) =>
                        handleFrequencyChange(
                          'major_function_id',
                          Number(value),
                        )
                      }
                    />
                  </Box>
                  <Box className={styles.fieldContainer}>
                    <Typography className={styles.label}>
                      Intermediate Function:
                    </Typography>
                    <SelectComponent
                      items={intermediateFunctions.map((elem) => ({
                        value: elem.id,
                        displayValue: elem.name,
                      }))}
                      label="Intermediate FN"
                      value={frequency?.intermediate_function_id}
                      className={styles.field}
                      handleChange={(value) =>
                        handleFrequencyChange(
                          'intermediate_function_id',
                          Number(value),
                        )
                      }
                    />
                  </Box>
                  <Box className={styles.fieldContainer}>
                    <Typography className={styles.label}>
                      Detailed Function:
                    </Typography>
                    <SelectComponent
                      items={detailedFunctions.map((elem) => ({
                        value: elem.id,
                        displayValue: elem.name,
                      }))}
                      label="Detailed FN"
                      value={frequency?.detailed_function_id}
                      className={styles.field}
                      handleChange={(value) =>
                        handleFrequencyChange(
                          'detailed_function_id',
                          Number(value),
                        )
                      }
                    />
                  </Box>
                  <Box className={styles.fieldContainer}>
                    <Typography className={styles.label}>
                      Usage Time:
                    </Typography>
                    <SelectComponent
                      items={usageTimes.map((elem) => ({
                        value: elem.id,
                        displayValue: elem.name,
                      }))}
                      label="Usage Time"
                      value={frequency?.usage_time_id}
                      className={styles.field}
                      handleChange={(value) =>
                        handleFrequencyChange('usage_time_id', Number(value))
                      }
                    />
                  </Box>
                  <Box className={styles.fieldContainer}>
                    <Typography className={styles.label}>
                      Usage Time Service:
                    </Typography>
                    <SelectComponent
                      label="Usage Time Service"
                      value={frequency?.usage_time_service_id}
                      className={styles.field}
                      handleChange={(value) =>
                        handleFrequencyChange(
                          'usage_time_service_id',
                          Number(value),
                        )
                      }
                      items={usageTimeServices.map((elem) => ({
                        value: elem.id,
                        displayValue: elem.name,
                      }))}
                    />
                  </Box>
                  <TextField
                    fullWidth
                    label="Description"
                    value={frequency?.supplementary_details || ''}
                    onChange={(evt) =>
                      handleFrequencyChange(
                        'supplementary_details',
                        evt.target.value,
                      )
                    }
                    type="area"
                  />
                </Box>
              </Box>,
            ]
          : []
      }
    />
  )
}

export default FrequencySection

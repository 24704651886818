import * as mgrs from 'mgrs'
import { Node, Position } from '@xyflow/react'
import { Direction } from '../algorithms'
import { EventStatus } from '../types/event.type'
import { DirectionTypes } from '../types/playbook.type'
import { darkModeTableColors, lightModeTableColors } from './constants'
import { IExerciseRequestFrequencySingle } from '../types/exercise.type'

export const EventColor = (status: EventStatus, isLightMode: boolean) => {
  switch (status) {
    case EventStatus.SUCCESS:
      return isLightMode ? 'green' : '#08f308'
    case EventStatus.ERROR:
      return isLightMode ? 'red' : '#ff4545'
    case EventStatus.WARNING:
      return isLightMode ? '#cc8400' : 'orange'
  }
}

export const getLayerCoords = (
  centerLat: number,
  centerLon: number,
  distanceKm: number,
) => {
  // Earth radius in kilometers
  const earthRadiusKm = 6371

  // Convert distance to radians
  const distanceRad = distanceKm / earthRadiusKm

  // Calculate the latitude and longitude offsets
  const latOffset = (distanceRad * 180) / Math.PI
  const lonOffset =
    (distanceRad * 180) / (Math.PI * Math.cos((centerLat * Math.PI) / 180))

  const geoCoords = [
    [centerLon + lonOffset, centerLat + latOffset],
    [centerLon - lonOffset, centerLat + latOffset],
    [centerLon - lonOffset, centerLat - latOffset],
    [centerLon + lonOffset, centerLat - latOffset],
  ]

  return geoCoords
}

export const getTimeDifference = (clientDate: string, clientTime: string) => {
  const now = new Date()

  const clientDateTime = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() + 1,
    0,
    0,
    0,
  )

  const time1 = clientDateTime.getTime()

  const differenceInMilliseconds = time1 - now.getTime()

  const differenceInMinutes = differenceInMilliseconds / (1000 * 60)

  return differenceInMinutes
}

export const getSourceHandlePosition = (direction: Direction) => {
  switch (direction) {
    case 'TB':
      return Position.Bottom
    case 'BT':
      return Position.Top
    case 'LR':
      return Position.Right
    case 'RL':
      return Position.Left
  }
}

export const getTargetHandlePosition = (direction: Direction) => {
  switch (direction) {
    case 'TB':
      return Position.Top
    case 'BT':
      return Position.Bottom
    case 'LR':
      return Position.Left
    case 'RL':
      return Position.Right
  }
}

export const compareNodes = (xs: Map<string, Node>, ys: Map<string, Node>) => {
  if (xs.size !== ys.size) return false

  for (const [id, x] of xs.entries()) {
    const y = ys.get(id)

    if (!y) return false

    if (x.resizing || x.dragging) return true
    if (x.width !== y.width || x.height !== y.height) return false
  }

  return true
}

export const isValidJSON = (jsonString: string) => {
  try {
    JSON.parse(jsonString)
    return true
  } catch (_) {
    return false
  }
}

export const isValidBase64 = (str: string) => {
  const base64Regex =
    /^(?:[A-Za-z0-9+\/]{4})*(?:[A-Za-z0-9+\/]{2}==|[A-Za-z0-9+\/]{3}=)?$/
  return base64Regex.test(str)
}

export const mergeRefs = (...inputRefs: any[]) => {
  return (ref: any) => {
    inputRefs.forEach((inputRef) => {
      if (!inputRef) {
        return
      }

      if (typeof inputRef === 'function') {
        inputRef(ref)
      } else {
        // eslint-disable-next-line no-param-reassign
        inputRef.current = ref
      }
    })
  }
}

export const getNewNodePosition = (
  nodes: Node[],
  direction: DirectionTypes,
) => {
  if (direction === DirectionTypes.VERTICAL) {
    const maxYNode = nodes.reduce(
      (maxNode, node) =>
        node.position.y > maxNode.position.y ? node : maxNode,
      nodes[0],
    )

    const newPosition = {
      x: maxYNode.position.x,
      y: maxYNode.position.y + 150,
    }

    return newPosition
  }

  const maxXNoe = nodes.reduce(
    (maxNode, node) => (node.position.x >= maxNode.position.x ? node : maxNode),
    nodes[0],
  )

  const newPosition = {
    x: maxXNoe.position.x + 400,
    y: maxXNoe.position.y,
  }

  return newPosition
}

/*
 * Converts degress/minutes/seconds to a decimal latitude or longitude.
 *
 * We also accept a direction, which should 'N', 'S', 'E', or 'W'.
 * South and West get mapped to negative lat/lon.
 *
 * Some of our data has both negative degrees *and* a direction,
 * which is redundant. So if we have a non-null direction, we use the
 * absolute value of the input.
 *
 * Note that we give latitude results for greater than +/-90 degrees,
 * even though that is nonsensical.
 */
export const dmsToDecimal = (
  degrees: number,
  minutes: number,
  seconds: number,
  direction: string | null,
): number => {
  const pdegrees = Math.abs(degrees)
  let decimal = pdegrees + minutes / 60 + seconds / 3600

  if (direction === 'S' || direction === 'W' || (!direction && degrees < 0)) {
    decimal = -decimal
  }

  return decimal
}

export const decimalToDMS = (
  decimal: number,
  isLatitude: boolean,
): {
  degrees: number
  minutes: number
  seconds: number
  direction: string
} => {
  const isNegative = decimal < 0
  const absDecimal = Math.abs(decimal)

  const absDegrees = Math.floor(absDecimal)
  const degrees = isNegative ? -absDegrees : absDegrees
  const minutesDecimal = (absDecimal - absDegrees) * 60
  const minutes = Math.floor(minutesDecimal)
  const seconds = Math.round((minutesDecimal - minutes) * 60)

  let direction = ''
  if (isLatitude) {
    direction = isNegative ? 'S' : 'N'
  } else {
    direction = isNegative ? 'W' : 'E'
  }

  return { degrees, minutes, seconds, direction }
}

export const convertToMGRS = (
  latDeg: number,
  latMin: number,
  latSec: number,
  latDir: string,
  lonDeg: number,
  lonMin: number,
  lonSec: number,
  lonDir: string,
): string => {
  const latitude = dmsToDecimal(latDeg, latMin, latSec, latDir)
  const longitude = dmsToDecimal(lonDeg, lonMin, lonSec, lonDir)

  const mgrsCoord = mgrs.forward([longitude, latitude])

  return mgrsCoord
}

export const mgrsToLonlat = (mgrsCoord: string) => {
  return mgrs.toPoint(mgrsCoord)
}

export const getTableCellColor = (
  colors: {
    [key: string]: { background: string; color: string }
  },
  value: string | number,
  isLightMode: boolean,
) => {
  if (colors[value]) {
    return colors[value]
  }

  const availableColors = isLightMode
    ? lightModeTableColors
    : darkModeTableColors

  const index = Math.floor(Math.random() * availableColors.length)

  return availableColors[index]
}

export const calculateAzimuth = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
): number => {
  try {
    const toRadians = (deg: number) => (deg * Math.PI) / 180

    const lat1InRads = toRadians(lat1)
    const lat2InRads = toRadians(lat2)
    const lonDifferenceInRads = toRadians(lon2 - lon1)

    const y = Math.sin(lonDifferenceInRads) * Math.cos(lat2InRads)
    const x =
      Math.cos(lat1InRads) * Math.sin(lat2InRads) -
      Math.sin(lat1InRads) *
        Math.cos(lat2InRads) *
        Math.cos(lonDifferenceInRads)
    let atanInRads = Math.atan2(y, x)

    atanInRads = (atanInRads * 180) / Math.PI

    const azimuth = (atanInRads + 360) % 360
    return isNaN(azimuth) ? 0 : Number(azimuth.toFixed())
  } catch (err: any) {
    if (err.message) {
      console.log(err.message)
    }

    return 0
  }
}

export const isFrequencyValid = (
  frequency: IExerciseRequestFrequencySingle | null,
) => {
  // Check is frequency null
  if (!frequency) {
    return false
  }

  // Check by template Id
  if (!frequency.is_custom && frequency.frequency_template_id) {
    return true
  }

  // Check by other params
  if (
    frequency.is_custom &&
    frequency.start_freq &&
    frequency.start_indicator &&
    frequency.end_freq &&
    frequency.end_indicator
  ) {
    return true
  }

  return false
}

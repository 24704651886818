import { FC, useEffect, useState } from 'react'
import {
  Box,
  Button,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import {
  IEmissionType,
  IStationClass,
  IExerciseRequestStationClass,
  IPowerRange,
} from '../../../../types/equipment.type'
import { IOperatingUnit } from '../../../../types/operating-unit.types'
import { IDeviceTransmitter } from '../../../../types/spectrum-manager.type'
import { SelectComponent } from '../../../shared/SelectComponent'
import { SelectWithSearch } from '../../../shared/SearchSelect'

import styles from '../SectionLayout/styles.module.scss'

interface IProps {
  powerRanges: IPowerRange[]
  stationClass: IExerciseRequestStationClass
  emissionTypes: IEmissionType[]
  operatingUnits: IOperatingUnit[]
  stationClasses: IStationClass[]
  stationClassDescriptions: IStationClass[]
  deviceTransmitters: IDeviceTransmitter[]
  handleChange: (id: number, key: string, value: string | number) => void
  handleDelete: (id: number) => void
}

export const StationClass: FC<IProps> = ({
  powerRanges,
  stationClass,
  emissionTypes,
  stationClasses,
  operatingUnits,
  stationClassDescriptions,
  deviceTransmitters,
  handleChange,
  handleDelete,
}) => {
  const [maxPowerValue, setMaxPowerValue] = useState(Infinity)

  useEffect(() => {
    const designator = deviceTransmitters.find(
      (transmitter) => transmitter.id === stationClass.emission_designator_id,
    )

    if (designator?.power) {
      const power = Number(designator.power)

      setMaxPowerValue(power)

      if (stationClass.power > power) {
        handleChange(stationClass.id, 'power', power)
      }
    }
  }, [stationClass.emission_designator_id])

  return (
    <>
      <Box key={stationClass.id} className={styles.titleContainer}>
        <Typography className={styles.title}>
          Station Class(Configuration) Information
        </Typography>
        <Button
          variant="outlined"
          color="error"
          onClick={() => handleDelete(stationClass.id)}
        >
          Delete
        </Button>
      </Box>
      <Box className={styles.subSection}>
        <Box className={styles.fieldContainer}>
          <Typography className={styles.label}>End User:</Typography>
          <SelectWithSearch
            width={350}
            value={operatingUnits
              .map((operatingUnit) => ({
                label: operatingUnit.unit_name,
                value: operatingUnit.id,
              }))
              .find((elem) => elem.value === stationClass.operating_unit_id)}
            label="Units"
            handleChange={(value) =>
              handleChange(stationClass.id, 'operating_unit_id', Number(value))
            }
            options={operatingUnits.map((operatingUnit) => ({
              value: operatingUnit.id,
              label: operatingUnit.unit_name,
            }))}
          />
        </Box>
        <Box className={styles.fieldContainer}>
          <Typography className={styles.label}>
            Station Configuration:
          </Typography>
          <SelectWithSearch
            width={350}
            value={stationClasses
              .map((stationClass) => ({
                label: stationClass.station,
                value: stationClass.station,
              }))
              .find((elem) => elem.value === stationClass.station_class_type)}
            label="Station Configuration"
            handleChange={(value) =>
              handleChange(stationClass.id, 'station_class_type', String(value))
            }
            options={stationClasses.map((stationClass) => ({
              value: stationClass.station,
              label: stationClass.station,
            }))}
          />
        </Box>
        <Box className={styles.fieldContainer}>
          <Typography className={styles.label}>Station Description:</Typography>
          <SelectWithSearch
            width={350}
            value={stationClassDescriptions
              .map((description) => ({
                label: description.station_description_options,
                value: description.id,
              }))
              .find((elem) => elem.value === stationClass.station_class_id)}
            label="Description"
            handleChange={(value) =>
              handleChange(stationClass.id, 'station_class_id', Number(value))
            }
            options={stationClassDescriptions.map((description) => ({
              value: description.id,
              label: description.station_description_options,
            }))}
          />
        </Box>
        <Box className={styles.fieldContainer}>
          <Typography className={styles.label}>Power:</Typography>
          <Box className={styles.field} sx={{ display: 'flex', gap: 2 }}>
            <TextField
              type="number"
              fullWidth
              className={styles.field}
              value={stationClass.power || undefined}
              inputProps={{ max: maxPowerValue }}
              label="Power"
              onChange={(evt) =>
                handleChange(stationClass.id, 'power', Number(evt.target.value))
              }
            />
            <Select
              value={stationClass.power_range_id}
              onChange={(evt) =>
                handleChange(
                  stationClass.id,
                  'power_range_id',
                  Number(evt.target.value),
                )
              }
            >
              {powerRanges.map((elem) => (
                <MenuItem key={elem.id} value={elem.id}>
                  {elem.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
        {stationClass.station_class_type === 'Aeronautical' && (
          <Box className={styles.fieldContainer}>
            <Typography className={styles.label}>
              Flight Level (KFT):
            </Typography>
            <TextField
              label="Flight Level (KFT)"
              className={styles.field}
              value={stationClass.flight_level || undefined}
              onChange={(evt) =>
                handleChange(
                  stationClass.id,
                  'flight_level',
                  Number(evt.target.value),
                )
              }
            />
          </Box>
        )}
        {(stationClass.station_class_type === 'Aeronautical' ||
          stationClass.station_class_type === 'Experimental' ||
          stationClass.station_class_type === 'Maritime' ||
          stationClass.station_class_type === 'Mobile') && (
          <Box className={styles.fieldContainer}>
            <Typography className={styles.label}>
              Radius of Operations(km):
            </Typography>
            <TextField
              label="Radius of Operations"
              className={styles.field}
              value={stationClass.antenna_radius}
              onChange={(evt) =>
                handleChange(
                  stationClass.id,
                  'antenna_radius',
                  Number(evt.target.value),
                )
              }
            />
          </Box>
        )}
        {stationClass.station_class_type === 'Fixed' && (
          <Box className={styles.fieldContainer}>
            <Typography className={styles.label}>
              Antenna Feed Point Height (meters):
            </Typography>
            <TextField
              className={styles.field}
              label="Antenna Height"
              value={stationClass.antenna_feed_point_height}
              onChange={(evt) =>
                handleChange(
                  stationClass.id,
                  'antenna_feed_point_height',
                  Number(evt.target.value),
                )
              }
            />
          </Box>
        )}
        <Box className={styles.fieldContainer}>
          <Typography className={styles.label}>Emission Designator:</Typography>
          <SelectWithSearch
            width={350}
            value={deviceTransmitters
              .map((transmitter) => ({
                label: transmitter.emission_designator,
                value: transmitter.id,
              }))
              .find(
                (elem) => elem.value === stationClass.emission_designator_id,
              )}
            options={deviceTransmitters.map((transmitter) => ({
              value: transmitter.id,
              label: transmitter.emission_designator,
            }))}
            label="Emission Designator"
            handleChange={(value) =>
              handleChange(
                stationClass.id,
                'emission_designator_id',
                Number(value),
              )
            }
          />
        </Box>
        <Box className={styles.fieldContainer} hidden>
          <Typography className={styles.label}>Emission Type:</Typography>
          <SelectComponent
            value={stationClass.emission_type_id}
            className={styles.field}
            items={emissionTypes.map((emission) => ({
              value: emission.id,
              displayValue: emission.description,
            }))}
            handleChange={(value) =>
              handleChange(stationClass.id, 'emission_type_id', Number(value))
            }
          />
        </Box>
      </Box>
    </>
  )
}
